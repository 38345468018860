import "../styles/base.scss"
import "../styles/loader.scss"
import "../styles/landing.scss"
import "../styles/coollinks.scss"
import "../styles/skills.scss"
import "../styles/reviews.scss"
import "../styles/swiper.scss"
import "../styles/projects.scss"
import "../styles/faq.scss"
import "../styles/contact.scss"
